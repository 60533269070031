<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 2322 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2321.16 0H1174.95V1146.21H2321.16V0Z" fill="#36599C" />
    <path
      d="M1589.59 805.441C1597.57 805.441 1604.79 803.819 1611.18 800.512L1611.19 800.506C1615.61 798.186 1619.66 795.005 1623.32 791L1623.32 802.599L1659.09 802.599L1659.09 655.503L1623.69 655.503L1623.69 718.843C1623.69 737.74 1621.37 750.498 1617.14 757.631L1617.14 757.642C1613.06 764.589 1606.41 768.173 1596.61 768.173C1586.97 768.173 1580.69 764.908 1577.07 758.795C1575.2 755.645 1573.69 750.964 1572.67 744.583C1571.65 738.23 1571.13 730.349 1571.13 720.911L1571.13 655.503L1534.63 655.503L1534.63 733.186C1534.63 745.886 1535.32 755.693 1536.77 762.499C1538.14 769.374 1540.45 775.546 1543.73 780.984L1543.75 781.017C1548.8 789.098 1555.19 795.233 1562.92 799.335C1570.57 803.437 1579.49 805.441 1589.59 805.441Z"
      fill="white"
    />
    <path
      d="M1494.62 408.892C1494.62 361.12 1464.06 341.524 1419.84 341.524H1360.75V565.299H1404.37V475.083H1404.97L1459.28 565.299H1512.11L1448.89 470.922C1478.87 465.886 1494.58 437.392 1494.58 408.892M1410 444.513H1404.35V377.14H1410.31C1433.45 377.14 1450.97 382.484 1450.97 410.084C1450.97 437.397 1434.05 444.513 1410.01 444.513"
      fill="white"
    />
    <path
      d="M1536.77 383.07C1541.64 383.07 1546.4 381.626 1550.45 378.92C1554.51 376.213 1557.66 372.366 1559.53 367.865C1561.39 363.365 1561.88 358.412 1560.93 353.634C1559.98 348.856 1557.63 344.467 1554.19 341.023C1550.74 337.578 1546.35 335.232 1541.58 334.282C1536.8 333.332 1531.85 333.819 1527.34 335.683C1522.84 337.548 1519 340.705 1516.29 344.755C1513.58 348.806 1512.14 353.568 1512.14 358.44C1512.13 361.677 1512.76 364.884 1514 367.876C1515.23 370.869 1517.04 373.588 1519.33 375.877C1521.62 378.166 1524.34 379.98 1527.33 381.214C1530.33 382.449 1533.53 383.08 1536.77 383.07Z"
      fill="white"
    />
    <path d="M1557.54 419.582H1515.99V565.297H1557.54V419.582Z" fill="white" />
    <path d="M1714.11 656.852H1672.56V802.567H1714.11V656.852Z" fill="white" />
    <path
      d="M1632.03 570.05C1639.69 570.049 1647.24 568.313 1654.13 564.971C1661.02 561.63 1667.06 556.77 1671.8 550.758H1672.4V560.267C1672.4 582.232 1671.8 608.049 1642.42 608.049C1635.25 608.329 1628.26 605.831 1622.89 601.076C1617.52 596.321 1614.2 589.676 1613.62 582.53H1569.69C1571.77 621.408 1607.68 643.079 1643.3 643.079C1697.92 643.079 1713.93 603.898 1713.93 556.414V419.582H1672.38V433.527H1671.79C1667.32 427.765 1661.61 423.09 1655.09 419.851C1648.56 416.612 1641.38 414.894 1634.09 414.825C1590.76 414.825 1566.43 453.41 1566.43 493.481C1566.43 532.657 1589.27 570.05 1632.02 570.05M1643 451.633C1664.35 451.633 1677.42 472.986 1677.42 492.592C1677.42 512.197 1664.37 533.248 1643 533.248C1621.63 533.248 1608.57 512.182 1608.57 492.592C1608.57 473.001 1621.63 451.633 1643 451.633Z"
      fill="white"
    />
    <path
      d="M1830.18 672.322H1829.58C1824.69 666.568 1818.59 661.963 1811.72 658.834C1804.84 655.704 1797.36 654.128 1789.81 654.216C1747.97 654.216 1723.33 692.499 1723.33 731.084C1723.33 770.558 1747.67 809.442 1790.41 809.442C1797.91 809.6 1805.36 808.021 1812.15 804.827C1818.95 801.634 1824.91 796.913 1829.58 791.033H1830.18V804.684H1871.72V565.326H1830.18V672.322ZM1799.89 772.634C1778.53 772.634 1765.46 751.281 1765.46 731.675C1765.46 712.07 1778.51 691.019 1799.89 691.019C1821.26 691.019 1834.31 712.085 1834.31 731.675C1834.31 751.266 1821.26 772.634 1799.89 772.634Z"
      fill="white"
    />
    <path
      d="M2022.04 733.459C2022.04 689.531 2000.68 654.213 1952.88 654.213C1905.7 654.213 1882.24 688.944 1882.24 733.171C1882.24 777.398 1909.85 809.448 1954.96 809.448C1985.53 809.448 2011.05 793.12 2021.14 764.034L1982.85 757.802C1977.22 769.378 1968.61 777.666 1954.96 777.666C1933.29 777.666 1926.16 756.893 1926.16 738.495H2022L2022.04 733.459ZM1927.36 712.687C1929.44 697.545 1938.63 683.298 1955.26 683.298C1971.29 683.298 1980.79 697.843 1982.85 712.687H1927.36Z"
      fill="white"
    />
    <path
      d="M1484.1 654.299C1482.86 649.13 1481.12 644.563 1478.88 640.598C1476.64 636.562 1473.9 633.163 1470.66 630.402C1467.48 627.569 1463.82 625.41 1459.69 623.923C1455.62 622.436 1451.11 621.692 1446.16 621.692C1436.91 621.692 1428.77 624.454 1421.75 629.977C1414.8 635.5 1409.37 643.536 1405.48 654.087C1401.59 664.566 1399.64 677.382 1399.64 692.535C1399.64 707.687 1401.56 720.574 1405.39 731.195C1409.22 741.816 1414.65 749.924 1421.66 755.518C1428.68 761.04 1436.96 763.802 1446.52 763.802C1455.18 763.802 1462.58 761.961 1468.71 758.279C1474.9 754.526 1479.62 749.251 1482.86 742.454C1486.17 735.656 1487.82 729 1487.82 719.725L1448.9 719.725V685.1H1524.7V712.502C1524.7 731.62 1521.33 748.047 1514.61 761.784C1507.89 775.45 1498.64 786 1486.84 793.435C1475.05 800.799 1461.55 804.481 1446.34 804.481C1429.36 804.481 1414.44 799.984 1401.59 790.992C1388.74 781.929 1378.71 769.077 1371.52 752.437C1364.39 735.727 1360.82 715.901 1360.82 692.96C1360.82 675.329 1362.94 659.609 1367.19 645.802C1371.49 631.924 1377.5 620.17 1385.23 610.54C1392.95 600.91 1401.94 593.582 1412.2 588.555C1422.46 583.527 1433.57 581.014 1445.54 581.014C1455.8 581.014 1465.35 582.819 1474.2 586.43C1483.04 589.971 1490.88 594.998 1497.72 601.512C1504.62 608.027 1510.25 615.78 1514.61 624.772C1518.98 633.694 1521.78 643.536 1523.01 654.299H1484.1Z"
      fill="white"
    />
    <path d="M1146.36 0H0.154053V1146.21H1146.36V0Z" fill="#36599C" />
    <path
      d="M547.127 473.271C547.127 424.068 526.822 341.524 390.825 341.524H236.841L352.78 540.34H249.365V804.682H320.904V605.012H390.427L505.636 804.682H585.906L463.828 594.395C517.768 576.021 547.127 533.706 547.127 473.271ZM429.733 536.075L353.505 406.201H390.825C465.532 406.201 475.608 440.098 475.608 473.271C475.608 508.762 461.753 528.065 429.733 536.075Z"
      fill="white"
    />
    <path
      d="M740.078 804.481H898.988V541.287H774.839V605.844H834.431V739.924H740.078C698.939 739.924 665.589 706.574 665.589 665.435V481.695C665.589 440.556 698.939 407.207 740.078 407.207H898.988V342.649H740.078C663.285 342.649 601.032 404.902 601.032 481.696V665.435C601.032 742.228 663.285 804.481 740.078 804.481Z"
      fill="white"
    />
  </svg>
</template>
